<div class='text-center col-12'>
  <h1>Hi, I'm Ian Leedham</h1>

  <p>I'm a Software Engineer</p>
  <p>I primarily specialise in dotnet, typescript, Angular, MsSql and Azure DevOps</p>
    <div class="row">
      <div class='col text-center'>
      <a href='mailto:ian.leedham@hotmail.com' target='_' class='m-1 ba'>
        <fa-icon [icon]="faEnvelope" size="2x" class="colour-primary"></fa-icon>
      </a>
      <a href='https://www.linkedin.com/in/ian-leedham/' target='_' class='m-1'>
        <fa-icon [icon]="faLinkedin" size="2x"  class="colour-primary" ></fa-icon>
      </a>
      <a href='https://github.com/ianleedham' target='_' class='m-1'>
        <fa-icon [icon]="faGithub" size="2x" class="colour-primary" ></fa-icon>
      </a>
    </div>
  </div>
</div>
