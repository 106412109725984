<div class="col-sm-8 offset-sm-2">
<img src="assets/ian-coffee-cropped.jpeg"
     [ngStyle]="{
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      clipPath: 'circle()'
      }"
      />

<app-scene>
    <svg:path
      id="Mountain__Large"
      d="M144.74 244l37.38-54 37.39 54H48"
      [ngStyle]="{
           'strokeDashoffset': 302.9,
           'strokeDasharray': 302.9,
           'animation': 'draw ' + 1.2 + 's ease-out forwards ' + -0.1 +'s'
      }"
      pathLength=302.9
      duration=1.2
      delay=-0.1
    ></svg:path>
    <svg:path
      id="Mountain__Small"
      d="M123 244l24.92-36 24.93 36"
      [ngStyle]="{
          'pathLength': 87.6,
          'strokeDashoffset': 87.6,
          'strokeDasharray': 87.6,
          'animation': 'draw ' + 0.6 + 's ease-out forwards ' + 0.8 +'s'
       }"
      pathLength=87.6
        ></svg:path>
   <svg:g [ngStyle]="{'transform': 'scale(1, -1) rotate(180deg)'}">
     <svg:path
       id="Mountain__Large"
       d="M144.74 244l37.38-54 37.39 54H48"
       [ngStyle]="{
           'strokeDashoffset': 302.9,
           'strokeDasharray': 302.9,
           'animation': 'draw ' + 1.2 + 's ease-out forwards ' + -0.1 +'s'
       }"
     ></svg:path>
     <svg:path
       id="Mountain__Small"
       d="M123 244l24.92-36 24.93 36"
       [ngStyle]="{
         'strokeDashoffset': 87.6,
         'strokeDasharray': 87.6,
         'animation': 'draw ' + 0.6 + 's ease-out forwards ' + 0.8 +'s'
       }"
     ></svg:path>
   </svg:g>
  <svg:g *ngFor="let section of repeater; index as index;" [ngStyle]="{ 'transform': 'rotate(' + 0 + ANGLE * index + 'deg)'}">
    <svg:path
      id="Ray__Main"
      d="M80 244H48"
      [ngStyle]="{
         'strokeDashoffset': 50,
         'strokeDasharray': 50,
         'animation': 'draw ' + 0.8 + 's ease-out forwards ' + 1.2 +'s'
       }"
    ></svg:path>
    <svg:path
      id="Ray__Tip"
      d="M41 244h-9"
      [ngStyle]="{
         'strokeDashoffset': 9,
         'strokeDasharray': 9,
         'animation': 'draw ' + 0.1 + 's ease-out forwards ' + 2 +'s'
       }"
    ></svg:path>
  </svg:g>
<!--  <app-scaled-path
    d="M116 258h248"
    id="Baseline"
    y={1}
    duration={0.6}
    delay={1.7}
    ></app-scaled-path>-->
</app-scene>
</div>
