<div class='col-12'>
  <div class='row'>
    <h1 class="col-12"> Skills </h1>
  </div>
  <div class='row'>
    <div class='col-12 col-md-3'>
      <app-skills-column [columnTitle]="'Languages'" [skills]="skills.languages" ></app-skills-column>
    </div>
    <div class='col-12 col-md-3'>
      <app-skills-column [columnTitle]="'Frameworks'" [skills]="skills.frameworks" ></app-skills-column>
    </div>
    <div class='col-12 col-md-3'>
      <app-skills-column  [columnTitle]="'Tools'" [skills]="skills.tools"></app-skills-column>
    </div>
    <div class='col-12 col-md-3'>
      <app-skills-column  [columnTitle]="'Concepts'" [skills]="skills.concepts"></app-skills-column>
    </div>
  </div>
</div>
