<div class="card bg-white text-white" (mouseenter) ="mouseEnter()"  (mouseleave) ="mouseLeave()" (click)="open()">
  <img src="{{imageUrl}}" class="card-img" alt="{{imageUrl}}" >

  <div class="card-img-overlay background-primary opacity-8" [style.display]="showExcerpt ? 'initial' : 'none'">
    <h4 class="card-title">
      {{title}}
    </h4>
    <p class="card-text p-2">
      <ng-container *ngTemplateOutlet="overlayContent"></ng-container>
    </p>
  </div>
</div>
