import {Component} from '@angular/core';

@Component({
  selector: 'app-experience',
  templateUrl: './projects.component.html',
  styleUrls: ['./projects.component.css']
})
export class ProjectsComponent {

  constructor() {
  }
}
